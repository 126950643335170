import { GetStaticProps } from 'next';
import { NextSeo } from 'next-seo';
import { useTranslation } from 'react-i18next';

import i18n from 'config/i18n';
import { BASE_MOBI_URL } from 'constants/api';
import InmobiContainer from 'containers/InmobiContainer';

export const getStaticProps: GetStaticProps = async (props) => {
  await i18n.changeLanguage(props.locale);

  return {
    props,
  };
};

export default function InmobiPage() {
  const { t } = useTranslation();
  return (
    <>
      <NextSeo
        title={t('qr.page.landing.seo.title', 'QR Code Generator:  Turn any link, vCard or file into a QR code')}
        description={t(
          'qr.page.landing.seo.description',
          'QR Code Generator: convert any link, vCard, facebook, image or video into a QR code, create QR restaurant menu, track QR code scans, customize design'
        )}
        canonical={BASE_MOBI_URL}
      />
      <InmobiContainer />
    </>
  );
}
