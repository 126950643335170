import InmobiLayout from 'containers/layouts/InmobiLayout';
import { COLORS } from 'styles';
import InmobiCTA from 'components/Landing/Inmobi/InmobiCTA';
import { MainContainer } from 'components/Landing/Inmobi/styled';

const InmobiContainer = () => {
  return (
    <InmobiLayout backgroundColor={COLORS.MAIN_BACKGROUND}>
      <MainContainer>
        <InmobiCTA />
      </MainContainer>
    </InmobiLayout>
  );
};

export default InmobiContainer;
