import styled from '@emotion/styled';
import { COLORS, mediaMax, breakpoints } from 'styles';

type ContentProps = {
  showFooter?: boolean;
  noBGImage?: boolean;
  backgroundColor?: string;
  heightAuto?: boolean;
};

export const Container = styled.section<ContentProps>`
  ${({ heightAuto }) => (heightAuto ? `min-height: unset;` : `min-height: 91.7vh;`)};

  width: 100%;
  background: ${({ backgroundColor }) => backgroundColor};
  ${({ noBGImage }) => (noBGImage ? `background: ${COLORS.MAIN_BACKGROUND};` : `background: ${COLORS.WHITE}`)}
  ${({ noBGImage }) =>
    noBGImage
      ? 'min-height: calc(100vh - 250px);'
      : `min-height: ${({ showFooter }: ContentProps) =>
          showFooter ? 'calc(100vh - 130px);' : 'calc(100vh - 73px);'}`}
    ${mediaMax(breakpoints.tabletXS)} {
    ${({ noBGImage }) => (noBGImage ? 'min-height: 685px;' : '')};
  }
  ${mediaMax(820)} {
    min-height: unset;
  }
`;
