/* eslint-disable react/no-unescaped-entities */
import { memo, FC, useCallback } from 'react';
import { LandingContentContainer } from 'containers/layouts/LandingLayout/styled';
import TranslationSpan from 'components/ui/TranslationSpan';
import {
  MainCTAWrapper,
  CTABLockWrapper,
  Title,
  DescWrapper,
  Button,
  ButtonWrapper,
  AppImage,
  AppImageWrapper,
  TextWrapper,
} from './styled';

import { useRouter } from 'next/router';
import { TEMPLATE_LINK, LINKS } from 'constants/constants';
import Image from 'next/image';
import { Trans, useTranslation } from 'react-i18next';
import Link from 'next/link';
import { useCrossDomainLink } from 'hooks/useCrossDomainLink';

const AppImageComponent: FC = memo(() => {
  const { t } = useTranslation();
  const imageUrls = {
    mobile: '/images/inmobi/inmobiLandingMobile.svg',
    desktop: '/images/inmobi/inmobiLandingDesktop.svg',
  };

  return (
    <AppImage>
      <source srcSet={imageUrls.desktop} media="(min-width: 1024px)" />
      <Image
        src={imageUrls.mobile}
        alt={t('qr.page.landing.seo.main.imagealt', 'create and customize QR codes')}
        width="600"
        height="400"
        loading="eager"
        fetchPriority="high"
      />
    </AppImage>
  );
});
const InmobiCTA = () => {
  const { i18n } = useTranslation();

  return (
    <LandingContentContainer>
      <MainCTAWrapper>
        <CTABLockWrapper>
          <Trans
            i18nKey="qr.page.landing.inmobi.title"
            defaults="<Title>What is myqrcode.mobi?</Title>"
            components={{
              Title: <Title />,
            }}
          />

          <DescWrapper>
            <TextWrapper>
              <Trans
                i18nKey="qr.page.landing.inmobi.description.synopsis"
                defaults="<div>If you're wondering what <Link>myqrcode.mobi</Link> is, you've probably come across a QR code like the one shown
              nearby.</div>"
                components={{
                  Link: <Link href={useCrossDomainLink('mobi', '/')} locale={i18n.language} />,
                  div: <div />,
                }}
              />
            </TextWrapper>
            <TextWrapper>
              <Trans
                i18nKey="qr.page.landing.inmobi.description.full"
                defaults="<div><Link>myqrcode.mobi</Link>, provided by <LinkMain>myqrcode.com</LinkMain>, is a dynamic QR code service. These codes act as links that
              quickly direct you to specific websites, landing pages, or mobile app content. Businesses use them to
              guide users to their sites efficiently and tract how often the QR codes are scanned. Interested in making
              your own QR codes? Click the button below to get started right away!</div>"
                components={{
                  Link: <Link href={useCrossDomainLink('mobi', '/')} locale={i18n.language} />,
                  LinkMain: <Link href={useCrossDomainLink('main', '/')} locale={i18n.language} />,
                  div: <div />,
                }}
              />
            </TextWrapper>
          </DescWrapper>
          <ButtonWrapper>
            <Button href={useCrossDomainLink('main', TEMPLATE_LINK)}>
              <TranslationSpan phrase="qr.page.landing.general.main.cta" />
            </Button>
          </ButtonWrapper>
        </CTABLockWrapper>
        <AppImageWrapper>
          <AppImageComponent />
        </AppImageWrapper>
      </MainCTAWrapper>
    </LandingContentContainer>
  );
};

export default InmobiCTA;
