import styled from '@emotion/styled';
import { mediaMinMax, mediaMax, mediaMin, breakpoints } from 'styles';
import { BlockTitle } from '../commonStyled';
import Link from 'next/link';

export const MainCTAWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;

  ${mediaMin(breakpoints.laptomSM)} {
    flex-flow: row;
  }
`;

export const CTABLockWrapper = styled.div`
  width: 100%;
  z-index: 1;

  ${mediaMin(breakpoints.tabletM)} {
    position: relative;
    width: 100%;
  }

  ${mediaMin(breakpoints.laptomSM)} {
    width: 31vw;
  }
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 25px;
  line-height: 37px;
  color: var(--qr-global-color-text-primary);

  ${mediaMin(breakpoints.tabletM)} {
    font-size: 25px;
    line-height: 37px;
    margin-top: 20px;
  }
  ${mediaMin(breakpoints.laptomSM)} {
    font-size: 35px;
    line-height: 43px;
    margin-top: 20px;
  }
`;

export const DescWrapper = styled.div`
  margin-top: 9px;
  margin-bottom: 22px;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  ${mediaMin(breakpoints.tabletM)} {
    margin: 20px 0 25px;
  }
`;

export const ButtonWrapper = styled.h2``;

export const Button = styled(Link)`
  border-radius: 6px;
  text-decoration: none;
  font-weight: 700;
  text-transform: none;
  word-break: break-all;
  word-break: break-word;
  width: 100%;
  height: 60px;
  background-color: var(--qr-global-color-yellow);
  color: var(--qr-global-color-white);
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    background-color: var(--qr-global-color-yellow-hover);
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  ${mediaMin(breakpoints.mobileL + 3)} {
    max-width: 288px;
  }

  a,
  a:hover {
    text-decoration: none;
    color: var(--qr-global-color-white);
  }
`;
export const AppImage = styled.picture`
  margin-top: 44px;
  flex-grow: 1;
  filter: drop-shadow(0px 1px 51px rgba(12, 37, 54, 0.09));

  ${mediaMin(breakpoints.laptomSM)} {
    position: absolute;
    margin-top: 0px;
    width: calc(100% - 60px);
    right: 0;
  }

  /** Custom image positiong to prevent horizontal scroll and show full image */
  ${mediaMinMax(1250, 1361)} {
    left: auto;
    right: -22px;
  }

  ${mediaMinMax(breakpoints.tabletL, 1250)} {
    left: auto;
    right: 0;
  }

  ${mediaMax(breakpoints.tabletXS)} {
    display: flex;
  }

  img {
    width: 100%;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const AppImageWrapper = styled.div`
  width: 100%;
  z-index: 1;
  position: relative;

  ${mediaMin(breakpoints.tabletM)} {
    width: 100%;
    padding: 40px;
  }

  ${mediaMin(breakpoints.laptomSM)} {
    width: 785px;
    height: 590px;
  }
`;

export const MainContainer = styled.div`
  margin-top: 72px;

  ${mediaMinMax(breakpoints.tabletL, breakpoints.laptomSM)} {
    margin-top: 52px;
  }

  ${mediaMin(breakpoints.laptomSM)} {
    margin-top: 100px;
  }
`;
